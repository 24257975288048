import { Divider } from "antd";
import { inject, observer } from "mobx-react";
import qs from "qs";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import SearchStore from "../../../../../../../stores/SearchStore";
import TreesStore from "../../../../../../../stores/TreesStore";
import { SearchResultsStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  results: any[];
  query: string;
  searchStore?: SearchStore;
  treesStore?: TreesStore;
  totalResults?: number;
}

class SearchResults extends React.PureComponent<Props, any> {
  onChangePageResultsSettings = async (page: any) => {
    // this.props!.searchStore!.currentPage = page;
    // await this.props!.searchStore!.search(
    //   this.props!.searchStore!.query,
    //   this.props!.searchStore!.searchType,
    //   this.props!.searchStore!.searchMethod,
    //   this.props!.searchStore!.searchArea,
    //   this.props!.searchStore!.skipDuplicates
    // );
    // window.scrollTo(0, 0);
  };
  setHighlight = (str: string, query?: any) => {
    if (!query) return str;

    var regEx = new RegExp(query, "gi");

    str = str.replace(
      regEx,
      (a: any, b: any) => '<span style="font-weight:bold;">' + a + "</span>"
    );
    return str;
  };

  getSearchParams = () =>
    qs.parse(this.props.location.search.replace("?", ""), {
      decoder: function (value: any) {
        if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value);
        }

        let keywords: any = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        };
        if (value in keywords) {
          return keywords[value];
        }

        return decodeURI(value);
      },
    });

  result(result: any, action: Array<string>) {
    let resultSource = null;
    let node, tree, subTitle;
    debugger
    if (result.treeId) {
      tree = this.props!.treesStore!.getTreeById(result.treeId);
      if (!tree) {
        // debugger;
      }
      if (tree) {
        node = this.props!.treesStore!.findDeep(
          tree!.children,
          result.tocId,
          "data"
        );
        if (node) {
          const _subTitle = [];
          let current = node;
          do {
            _subTitle.push(current.text);
            current = current.parent;
            // debugger;
          } while (current !== null);

          subTitle = _subTitle.reverse().join("/");
        }
        if (!node) {
          // debugger;
        }
      }
      resultSource = tree?.name;
    } else {
      node = this.props!.treesStore!.findDeep(
        this.props!.treesStore!.trees!.books!.children,
        result.docId,
        "bookLinkId"
      );
      if (node) {
        resultSource = "ספרים";
      }
      if (!node) {
        node = this.props!.treesStore!.findDeep(
          this.props!.treesStore!.trees!.lessons!.children,
          result.docId,
          "bookLinkId"
        );
        if (node) {
          resultSource = "שיעורים";
        }
      }

      if (!node) {
        node = this.props!.treesStore!.findDeep(
          this.props!.treesStore!.trees!.audio!.children,
          result.docId,
          "bookLinkId"
        );
        if (node) {
          resultSource = "אודיו";
        }
      }
    }

    console.log({node})
    if (!node) {
      // debugger;
      return null;
    }
    if (node) {
      // debugger;
    }

    // console.log({ node });
    // let resultSource: string = "ספרים";
    // let tree: any, node: any;
    // const dataToFind = result.docId || result.tocId;
    // debugger;
    // if (result.treeId) {
    //   tree = this.props.treesStore?.getTreeById(result.treeId);
    //   node = this.props!.treesStore!.findDeep(
    //     tree.children,
    //     dataToFind,
    //     "bookLinkId"
    //   );
    // } else {
    //   node = this.props!.treesStore!.findDeep(
    //     this.props!.treesStore!.trees!.books!.children,
    //     dataToFind,
    //     "bookLinkId"
    //   );
    //   if (node) {
    //     tree = this.props!.treesStore!.trees!.books!.children;
    //   }
    //   if (!node) {
    //     node = this.props!.treesStore!.findDeep(
    //       this.props!.treesStore!.trees!.lessons!.children,
    //       dataToFind,
    //       "bookLinkId"
    //     );
    //     if (node) {
    //       tree = this.props!.treesStore!.trees!.lessons!.children;
    //     }
    //   }

    //   if (!node) {
    //     node = this.props!.treesStore!.findDeep(
    //       this.props!.treesStore!.trees!.audio!.children,
    //       dataToFind,
    //       "bookLinkId"
    //     );
    //     if (node) {
    //       tree = this.props!.treesStore!.trees!.audio!.children;
    //     }
    //   }
    // }
    // if (!node || !tree) {
    //   console.log(`Invalid params in search results`);
    //   return null;
    // }

    // debugger;
    // resultSource = tree!.name;

    // if (!node) {
    //   node = this.props!.treesStore!.findDeep(
    //     this.props!.treesStore!.trees!.audio!.children,
    //     result.doc_id,
    //     "bookLinkId"
    //   );
    // }
    const href = node ? `${node.route}${result.tocId || ""}` : "";

    return (
      <SearchResultsStyled
        className="flex paddingHor40 br-bottom br-blue textRight flex-col-mobile"
        key={Math.random()}
      >
        <div className="flex1">
          <div className="f18 bold">
            {result?.meta_data?.name ||
              result?.tocName ||
              result?.text ||
              result?.meta_data?.docName}
          </div>
          {subTitle ||
            (result?.meta_data?.subtitle && (
              <div className="f22 ">
                {result?.meta_data?.subtitle || subTitle}
              </div>
            ))}
          {!subTitle && (
            <div className="f18 marginTop20">
              {/* <TextTruncate
                line={3}
                element="div"
                truncateText="…"
                text={this.setHighlight(
                  result.text,
                  this.getSearchParams()["query"]
                )}
              /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: this.setHighlight(
                    result.text,
                    this.getSearchParams()["query"]
                  ),
                }}
              ></div>
            </div>
          )}
          <div className="flex gray marginTop20 flex-wrap-mobile">
            {resultSource && (
              <>
                <div className="flex">
                  <div className="marginLeft5">מקור התוכן באתר :</div>
                  <div className="">{resultSource}</div>
                </div>
              </>
            )}

            {result?.meta_data?.editor && (
              <>
                <Divider className="marginTop5 hide-mobile" type="vertical" />
                <div className="flex">
                  <div className="marginLeft5">שם העורך:</div>
                  <div className="">{result?.meta_data?.editor}</div>
                </div>
              </>
            )}

            {result?.meta_data?.reliabilityLevel && (
              <>
                <Divider className="marginTop5 hide-mobile" type="vertical" />
                <div className="flex">
                  <div className="marginLeft5">רמת עריכה:</div>
                  <div className=""> {result?.meta_data?.reliabilityLevel}</div>
                </div>
              </>
            )}

            {result?.main_doc && (
              <>
                <Divider className="marginTop5 hide-mobile" type="vertical" />
                <div className="flex">
                  <div className="">{result?.main_doc}</div>
                </div>
              </>
            )}

            {result?.meta_data?.date && (
              <>
                <Divider className="marginTop5 hide-mobile" type="vertical" />
                <div className="flex">
                  <div className="marginLeft5">תאריך:</div>
                  <div className="">{result?.meta_data?.date}</div>
                </div>
              </>
            )}
            {result?.meta_data?.location && (
              <>
                <Divider className="marginTop5 hide-mobile" type="vertical" />
                <div className="flex">
                  <div className="marginLeft5">מיקום:</div>
                  <div className="">{result?.meta_data?.location}</div>
                </div>
              </>
            )}
          </div>

          <Link
            to={href}
            className="f18 marginTop20 b-blueT textCenter white padding10   radius pointer content-link  show "
            style={{ width: "200px" }}
          >
            עבור לעיון
          </Link>
        </div>
        {/* <div className="">
          <div className="f18 marginTop10 underLine blue2 pointer">
            <div className="padding20 no-padding-mobile">
              <div className=" textCenter blueT ">
                <div className="flex justifyEnd marginBottom40 no-margin-mobile justify-content-mobile">
                  {action !== undefined &&
                    action.map(function (item, i) {
                      return (
                        <div className="" key={Math.random()}>
                          {item === "book" ? (
                            <ReactSVG
                              className=""
                              src="/images/text-icon-c.svg"
                            />
                          ) : null}

                          {item === "audio" ? (
                            <ReactSVG
                              className="marginLeft20"
                              src="/images/audio-icon-c.svg"
                            />
                          ) : null}

                          {item === "video" ? (
                            <ReactSVG
                              className="marginLeft20"
                              src="/images/video-icon-c.svg"
                            />
                          ) : null}
                        </div>
                      );
                    })}
                </div>

                <div className="flex-mobile justify-content-mobile mobile-tags">
                  <div className="flex justifyEnd">
                    <div className="f15 br br-blue  padding5 radius margin5 ">
                      מעניין
                    </div>
                    <img
                      alt="1"
                      className="fleft"
                      width="15"
                      style={{ marginRight: "13px" }}
                      src="/images/tag.svg"
                    />
                  </div>

                  <div className="flex justifyEnd">
                    <div
                      className="f18 fw400 blueT  marginLeft20"
                      style={{ marginRight: "8px" }}
                    >
                      204
                    </div>
                    <img
                      alt="1"
                      className="fleft"
                      width="15"
                      style={{ marginRight: "13px" }}
                      src="/images/like/like-outline.svg"
                    />
                  </div>
                </div>
              </div>
              <Link
                to={href}
                className="f18 marginTop20 b-blueT textCenter white padding10   radius pointer content-link full-mobile show hide-desktop"
                style={{ width: "200px" }}
              >
                עבור לעיון
              </Link>
            </div>
                  </div>
        </div> */}
      </SearchResultsStyled>
    );
  }
  render() {
    const { query, results, totalResults } = this.props;
    console.log({query, results, totalResults })
    return (
      <SearchResultsStyled className="marginHor50 padding-mobile">
        <div className="blue ">
          <div className="f24  textRight">
            תוצאות חיפוש עבור: <span className="bold"> {query}</span>
          </div>
          <div className="f18 textRight">
            ({`סה״כ תוצאות: ${totalResults}`})
          </div>
          {results.map((book: any, i: number) =>
            this.result(book, ["video", "audio", "book"])
          )}
        </div>
      </SearchResultsStyled>
    );
  }
}
export default withRouter(inject("treesStore", "searchStore")(observer(SearchResults)));
