import { DownloadOutlined, SoundOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TreesStore from "../../../../../../../stores/TreesStore";
import { BookMediaStyled } from "./styled";

type media = {
  id: string;
  title: string;
};

type mediaLink = {
  tocId: string;
  media: media[];
};
interface Props extends RouteComponentProps<any> {
  mediaLinks: mediaLink;
  treesStore?: TreesStore;
}

class BookMedia extends React.PureComponent<Props, any> {
  player: any;
  link: any;
  currentMedia: any = {};
  idInterval: any;
  audioLink: any;

  componentDidMount = async () => {
    const { mediaLinks } = this.props;

    this.player = new Plyr(document.getElementById("plyr") || "", {
      loadSprite: true,
    });
    this.setCurrentMedia(mediaLinks.media[0].id);

    this.player.on(
      "loadedmetadata",
      function (this: any) {
        setTimeout(() => {
          const currentTime = localStorage.getItem(
            `audio_correct_time_${mediaLinks.media[0].id}`
          );
          if (currentTime) {
            setTimeout(
              () => (this.player.currentTime = Number(currentTime)),
              0
            );
          }

          this.idInterval = setInterval(() => {
            console.log("set time 2");
            localStorage.setItem(
              `audio_correct_time_${mediaLinks.media[0].id}`,
              this.player.currentTime
            );
          }, 10000);
        }, 6500);
      }.call(this)
    );
  };

  componentWillUnmount = async () => {
    clearInterval(this.idInterval);
  };

  getTime = () =>
    console.log("this.player.currentTime :", this.player.currentTime);

  setCurrentMedia = async (id: string) => {
    const { treesStore } = this.props;
    this.currentMedia = await treesStore?.getAudioDetails(id);
    let { link } = this.currentMedia;
    if (link) {
      const id = link
        .replace("/view?usp=sharing", "")
        .replace("https://drive.google.com/file/d/", "")
        .replace("https://drive.google.com/open?id=", "")
        .replace("https://docs.google.com/uc?export=download&id=", "");

      if (!link.includes("dropbox")) {
        link = `https://docs.google.com/uc?export=download&id=${id}`;
      }

      this.audioLink = link;

      this.player.source = {
        type: "audio",
        sources: [
          {
            src: this.audioLink,
          },
        ],
      };
    }
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      currentMedia: observable,
      idInterval: observable,
      audioLink: observable
    });
  }

  render() {
    const { mediaLinks } = this.props;
    return (
      <BookMediaStyled>
        <div className="flex verticalAlign plyer marginBottom20">
          <audio id="plyr" />
          <Button
            className="download-button"
            type="link"
            icon={<DownloadOutlined />}
            href={this.audioLink}
            download
          />
        </div>
        <div className="playlist">
          {mediaLinks.media.map((media: any, index: number) => (
            <div
              onClick={() => this.setCurrentMedia(media.id)}
              key={index}
              className={`media-link-item ${
                this.currentMedia._id === media.id ? "current" : ""
              }`}
            >
              <SoundOutlined /> {media.title}
            </div>
          ))}
        </div>
      </BookMediaStyled>
    );
  }
}
export default withRouter(inject("treesStore")(observer(BookMedia)));
