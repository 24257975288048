// import axios from "axios";
import BaseHttpService from "./base.http.service";
//import { toJS } from "mobx";
// import Qs from 'qs';

class Api extends BaseHttpService {
  // baseUrl: string = "http://35.181.48.41:26000";
  baseUrl: string = "https://galeinai.org.il:8443/api";

  fetchTableOfContent = (bookId: number) => {
    return this.get(`/Json/TreeStructure/${bookId}`);
  };

  getTree = (id: string | number) => {
    return this.get(`/Json/SubjectsTreeStructure/${id}`);
  };

  getBooks = () => {
    return this.getTree(`1480662372`);
  };
  getSubjects = () => {
    return this.getTree(`1479482716`);
  };

  getLessons = () => {
    return this.getTree(`2759928088`);
  };

  getAudio = () => {
    return this.getTree(`1466506535`);
  };

  getNewsTree = () => {
    return this.getTree(`942617850`);
  };

  getFavoriteTree = () => {
    return this.getTree(`2753440010`);
  };

  fetchAudioDetails = (id: string) => {
    return this.get(`/Json/GetMediaDetails/${id}`);
  };

  fetchContentDetails = (id: string) => {
    return this.get(`/Json/GetBookDetails/${id}`);
  };

  fetchChapter = (fileId: any, toc: any) => {
    return this.get(`/Json/GetHtmlOfToc/${fileId}/${toc}/`);
  };

  getBookHtml = (id: number) => {
    return this.get(`/Json/GetHtmlContent/${id}`);
  };

  /**
   * searchType codes:
      1 – search text in all content
      2 – search text in all headers of books
      3 – search text in website tress headers
      4 – search in lesson details.
      
      searchArea is json array of booleans, like this: 	{'books':true,'subjects':false,'audio':true,'lessons':false}

      searchMethod codes:
      1.	Exact -  all text in sequence
      2.	Jumps – all text with jumps
      3.	Part – part of the text (quite needless option …)

      http://35.181.48.41:26000/search/1/{'books':true,'subjects':false,'audio':true,'lessons':false}/1/50/0/שלום

   * @param query 
   * @param searchType 
   * @param searchMethod 
   * @param searchArea 
   * @param skipDuplicates 
   */
  searchTextInBookAndLessons = (
    // query: string,
    // searchType: string,
    // searchMethod: string,
    // searchArea: number,
    // skipDuplicates: string,
    // currentPage: number,
    // resultsPerPage: number
    searchParams: any
  ) => {
    // console.log({
    //   query,
    //   searchType,
    //   searchMethod,
    //   searchArea,
    // });
    console.log({searchParams})
    const data = {

      resultsNum:
        searchParams.currentPage === 1
          ? 50
          : searchParams.currentPage * searchParams.pageSize,
      skipNum: searchParams.currentPage === 1 ? 0 : searchParams.currentPage,
      text: searchParams.query,
      "useMelingo":true,
      "searchMethod":searchParams?.searchMethod===2?"Gaps":'Exact',
      "searchArea": {lessons:true,books:true,trees:true},
      ...(searchParams?.searchMethod===1? searchParams:{}),
    };

    return this.post(`${searchParams?.searchMethod===1 ?'/search/':'/ElasticSearch/'}`, data);
  };

  fetchNotes = (bookLinkId: string) => {
    return this.get(`/Json/GetFootNotes/${bookLinkId}`);
  };
}

export default Api;
