export type OptionsRequest = {
  abort: () => void;
  promise: Promise<any>;
};

interface BaseService {
  get(path: string, options?: {}): OptionsRequest;
  post(path: string, data?: {}, options?: {}): OptionsRequest;
  put(path: string, data?: {}, options?: {}): OptionsRequest;
  del(path: string, options?: {}): OptionsRequest;
}
interface ICommonOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  mode?: "no-cors" | "cors" | "same-origin";
  cache?: "default" | "no-cache" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "include" | "same-origin" | "omit";
  headers?: { [key: string]: string };
  redirect?: "manual" | "follow" | "error";
  referrerPolicy?:
    | "no-referrer"
    | "no-referrer-when-downgrade"
    | "origin"
    | "origin-when-cross-origin"
    | "same-origin"
    | "strict-origin"
    | "strict-origin-when-cross-origin"
    | "unsafe-url";
  body?: any;
}
class BaseHttpService implements BaseService {
  baseUrl: string = "";

  commonOptions: ICommonOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(baseUrl: string = "") {
    this.baseUrl = baseUrl;
  }

  get(path: string, options: {} = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;

    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "GET" },
      signal,
    });

    return {
      promise: response,
      abort: controller.abort,
    } as OptionsRequest;
  }

  post(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "POST" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: response,
      abort: controller.abort,
    } as OptionsRequest;
  }

  put(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "PUT" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: response,
      abort: controller.abort,
    } as OptionsRequest;
  }

  del(path: string, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "DELETE" },
      signal,
    });

    return {
      promise: response,
      abort: controller.abort,
    } as OptionsRequest;
  }

  streamAsyncIterator = async function* streamAsyncIterator(stream: any) {
    // Get a lock on the stream
    const reader = stream.getReader();

    try {
      while (true) {
        // Read from the stream
        const { done, value } = await reader.read();
        // Exit if we're done
        if (done) return;
        // Else yield the chunk
        yield value;
      }
    } finally {
      reader.releaseLock();
    }
  };
}

export default BaseHttpService;
